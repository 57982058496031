$(function () {
    $('input[name=phone]').inputmask("+7 999-999-99-99", {
        "clearIncomplete": true,
        "showMaskOnHover": false
    });

    $('.help-sect__right form').on('submit', function (e) {
        //- Остановка отправки формы https://learn.javascript.ru/forms-submit
        e.preventDefault();

        //- Переменная для отправки формы
        var $this = $(this);

        //- Валидация ------------------------------


        //- Получаем все данные из инпутов а так же создаем регулярное выражение на проверку email
        var phone = $this.find('input[name=phone]').val();
        var check = $this.find('input[name=agree]');

        //- Проверка на пустое поле
        if (phone.length !== 0) {
            //- Проверка выполнена успешно (поле заполнено)
            $this.find('input[name=phone]').parent().removeClass('empty');
        } else {
            //- Проверка неудачна (поле пустое)
            $this.find('input[name=phone]').parent().addClass('empty');
        }

        if (check.prop('checked')) {
            $this.find('input[name=phone]').parent().removeClass('empty-check');
        } else {
            $this.find('input[name=phone]').parent().addClass('empty-check');
        }


        //- Проверка на соответсвие всех полей необходимым проверкам
        if (phone.length !== 0 && check.prop('checked')) {
            //- Конец валидации ----------------------------------------------



            //- Выполнить ajax отправку данных на сервер
            $.ajax({
                url: $this.attr('action'), // Файл в который отправить данные 
                type: $this.attr('method'), // Метод отправки данных
                data: $this.serialize(), // Получить все данные формы  https://ruseller.com/jquery?id=8
                success: function () { // Функция выполняемая в случае успешной отправки формы
                    //- Очистить форму после отправки
                    $this[0].reset();
                }
            });
        }
    });


});