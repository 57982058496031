$(window).on('load resize', function () {
    $('.header__bottom-menu>li').each(function () {
        var menuLeft = $(this).offset().left,
            dropdownRight = $('.header__bottom').width() - ($(this).find('.header__bottom-menu-dropdown').offset().left + $(this).find('.header__bottom-menu-dropdown').outerWidth());

        if (menuLeft < 248 && dropdownRight > 0) {
            $(this).find('.header__bottom-menu-dropdown').attr('style', 'transform: translateX(-' + (menuLeft + 10) + 'px);');
            $(this).find('.header__bottom-menu-dropdown>span').attr('style', 'transform: translateX(' + (menuLeft - 8) + 'px);');
        } else if (menuLeft >= 248 && dropdownRight > 0) {
            $(this).find('.header__bottom-menu-dropdown').attr('style', 'transform: translateX(-248px);');
            $(this).find('.header__bottom-menu-dropdown>span').attr('style', 'transform: translateX(240px);');
        } else if (menuLeft >= 248 && dropdownRight < 10) {
            dropdownRight = dropdownRight * -1;
            $(this).find('.header__bottom-menu-dropdown').attr('style', 'transform: translateX(-' + (248 + dropdownRight + 10) + 'px);');
            $(this).find('.header__bottom-menu-dropdown>span').attr('style', 'transform: translateX(' + (248 + dropdownRight + 10 - 8) + 'px);');
        }
    });
});

$('.header__mobile-menu>li').on('click', function () {
    $(this).toggleClass('active').find('.header__mobile-menu-dropdown').slideToggle(300);
});

// --- Поиск в header__top
$('.header__top-search input').on('focus', function () {
    $(this).addClass('active');
    setTimeout(function () {
        $('.header__top-search span').hide();
    }, 50)

});
$('.header__top-search input').on('focusout', function () {
    if ($(this).val().length == 0) {
        $(this).removeClass('active');
        setTimeout(function () {
            $('.header__top-search span').show();
        }, 150)

    }
})
// ---

// --- Поиск в header__bottom
$('.header__bottom-search a').on('click', function (e) {
    e.preventDefault();
    $(this).siblings('.header__bottom-search-wp').fadeToggle().find('input').focus();
});


$('.header__bottom-search input').on('focusout', function () {

    $('.header__bottom-search-wp').fadeOut();

});
// ---

var mobileMenu = new SmModal({
    modalSelector: '#header__mobile',
    closeSelector: '.header__mobile-close',
    openAnimate: 'smFadeLeft',
    closeAnimate: 'smFadeLeft',
    sideModal: 'left',
    verticalCenter: false,
});

$('.header__bottom-all-burger-mob').on('click', function (e) {
    e.preventDefault();
    mobileMenu.openModal();
});

var callbackModal = new SmModal({
    modalSelector: '#callback__modal',
    openAnimate: 'smFadeUp',
    closeAnimate: 'smFadeUp',
});
$('.header__middle-callback, .header__mobile-callback').on('click', function (e) {
    e.preventDefault();
    callbackModal.openModal();
});

var measurerModal = new SmModal({
    modalSelector: '#measurer__modal',
    openAnimate: 'smFadeUp',
    closeAnimate: 'smFadeUp',
});
$('.call-measurer').on('click', function (e) {
    e.preventDefault();
    measurerModal.openModal();
});

var calculationModal = new SmModal({
    modalSelector: '#calculation__modal',
    openAnimate: 'smFadeUp',
    closeAnimate: 'smFadeUp',
});
$('.call-calculation, .card__price-right a').on('click', function (e) {
    e.preventDefault();
    calculationModal.openModal();
});

$('.valid-form').on('submit', function (e) {
    e.preventDefault();

    var validForm = true;

    $(this).find('input').each(function () {
        var thisVal = $(this).val();
        if ($(this).attr('type') !== 'checkbox' && $(this).hasClass('required')) {
            if (thisVal == 0) {
                $(this).closest('.input-block').addClass('error').find('.information').text('Поле обязательно для заполнения');
                validForm = false;
            } else {
                $(this).closest('.input-block').removeClass('error').find('.information').text('');
            }
        } else if ($(this).attr('type') == 'checkbox') {
            if ($(this).is(':checked')) {
                $(this).closest('.default-checkbox').removeClass('default-checkbox--error');
            } else {
                $(this).closest('.default-checkbox').addClass('default-checkbox--error');
                validForm = false;
            }
        }
    });

    $(this).find('textarea.required').each(function () {
        var thisVal = $(this).val();

        if (thisVal == 0) {
            $(this).closest('.input-block').addClass('error').find('.information').text('Поле обязательно для заполнения');
            validForm = false;
        } else {
            $(this).closest('.input-block').removeClass('error').find('.information').text('');
        }
    });

    if (validForm == true) {
        console.log('отправка ajax');
    };
});