$(function () {

    cardSwiper = {
        width: Infinity,
        swiperContainer: '.card__swiper',
        swiperContainerWrap: '.card__swiper .swiper-container',
        swiperContainerSlide: '.card__swiper .swiper-slide',
        swiperVariable: undefined,
        swiperUpdateTime: undefined,
        swiperSettings: {
            slidesPerView: 1,
            speed: 500,
            navigation: {
                nextEl: '.card__detail-img .main-page__swiper-next',
                prevEl: '.card__detail-img .main-page__swiper-prev',
            },
        },
    };
    initCustomSwiper(cardSwiper);
    $(window).resize(function () {
        initCustomSwiper(cardSwiper);
    });

    $('.card__tabs-head a').on('click', function (e) {
        e.preventDefault();

        var $this = $(this);
        $('.card__tabs-head a').not($this).removeClass('active');
        $this.addClass('active');
        $('.card__tabs-body>div').hide();
        $('.card__tabs-body ' + $this.attr('href') + '').fadeIn();
    });

    if ($('main').hasClass('card')) {
        var questModal = new SmModal({
            modalSelector: '#quest_modal',
        });

        $('.card__quest a').on('click', function (e) {
            e.preventDefault();
            questModal.openModal();
        });

        var reviewModal = new SmModal({
            modalSelector: '#review__modal',
        });

        $('.card__review-more').on('click', function (e) {
            e.preventDefault();
            reviewModal.openModal();
        });
    }




});