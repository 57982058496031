$(function () {

    $(document).on('click', '.product-card__favor', function (e) {
        e.preventDefault();
        $(this).toggleClass('favorit');
    });

    var productSwiper = [];

    $('.main-page__swiper.swiper-container').each(function (e) {
        $(this).addClass("number" + e);
        $(this).siblings('.main-page__swiper-control').addClass("number" + e);
        productSwiper[e] = {
            width: Infinity,
            swiperContainer: '.main-page__swiper.swiper-container.number' + e,
            swiperContainerWrap: '.main-page__swiper.swiper-container.number' + e + ' .swiper-wrapper',
            swiperContainerSlide: '.main-page__swiper.swiper-container.number' + e + ' .swiper-slide',
            swiperVariable: undefined,
            swiperUpdateTime: undefined,
            swiperSettings: {
                slidesPerView: 'auto',
                speed: 500,
                navigation: {
                    nextEl: '.number' + e + ' .main-page__swiper-next',
                    prevEl: '.number' + e + ' .main-page__swiper-prev',
                },
            },
        };
        initCustomSwiper(productSwiper[e]);
        $(window).resize(function () {
            initCustomSwiper(productSwiper[e]);
        });
        setTimeout(function () {
            productSwiper[e].swiperVariable.update();
        }, 200);

    });

    $('.main-page__tabs-head a').on('click', function (e) {
        e.preventDefault();
        $('.main-page__tabs-head a').not($(this)).find('span').css('color', '#000000');
        $(this).find('span').css('color', '#C76C69');
        // console.log($('.main-page__tabs-body ' + $(this).attr('href') + ''));
        $('.main-page__tabs-body>div').not($(this).attr('href')).hide(0);
        $('.main-page__tabs-body ' + $(this).attr('href') + '').fadeIn();

        $('.main-page__swiper.swiper-container').each(function (e) {
            setTimeout(function () {
                productSwiper[e].swiperVariable.update();
            }, 200);
        });
    });

    $('.main-page__interior-el').on('click', function (e) {
        e.preventDefault();

        $('.main-page__interior-el').not($(this)).removeClass('active');
        $(this).addClass('active');

        var num = $(this).data('num');
        var img = $(this).siblings('.main-page__interior-img');

        img.find('.main-page__interior-img-el').each(function (e) {
            if ($(this).data('num') == num) {
                $(this).fadeIn();
            } else {
                $(this).hide();
            }
        });

    });

    $(window).on('load resize', function () {
        $('.main-page__insta-grid-el').css('height', $('.main-page__insta-grid-el').css('width'));
    });


});